.recognize-customer-section {
  .inner-wrapper {
    background: url('/is/image/anf/HCO_Squiggles_TopLeft@2x?$PNG$') top left no-repeat, url('/is/image/anf/HCO_Squiggles_TopRight@2x?$PNG$') top right no-repeat;
    margin: 0 rem(20);

    .header .h2 {
      text-transform: uppercase;
    }
  }
  @include media-query(700) {
    .inner-wrapper {
      background: none;
    }
  }
}